import React from "react";
import PropTypes from "prop-types";

function PopularCard({
  url,
  urlToImage,
  title,
  content,
  isSelected,
  onSelect,
}) {
  return (
    <div
      className={`w-full max-w-sm bg-white border rounded-xl overflow-hidden transition-all duration-300 hover:shadow-lg hover:-translate-y-1 flex flex-col ${
        isSelected
          ? "border-primary-500 shadow-md shadow-primary-200"
          : "border-neutral-200"
      }`}
    >
      <img src={urlToImage} alt={title} className="w-full h-32 object-cover" />
      <div className="p-4 flex-grow flex flex-col">
        <h3 className="text-lg font-semibold text-neutral-800 mb-2 line-clamp-2">
          {title}
        </h3>
        <p className="text-sm text-neutral-600 flex-grow line-clamp-3">
          {content}
        </p>
      </div>
      <div className="flex justify-between items-center px-4 py-3 border-t border-neutral-200">
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm font-medium text-primary-500 hover:text-primary-600"
        >
          Read more
        </a>
        <button
          className={`px-4 py-1 rounded-full text-sm font-medium transition-colors duration-200 ${
            isSelected
              ? "bg-primary-500 text-white"
              : "bg-white text-primary-500 border border-primary-500 hover:bg-primary-50"
          }`}
          onClick={onSelect}
        >
          {isSelected ? "Following" : "Follow"}
        </button>
      </div>
    </div>
  );
}

PopularCard.propTypes = {
  url: PropTypes.string.isRequired,
  urlToImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default PopularCard;
