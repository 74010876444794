import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Category from "./Category";
import { FaChevronRight, FaCheckCircle, FaInfoCircle } from "react-icons/fa";

const categories = {
  headlineNews: ["U.S.", "World", "Politics"],
  scienceNews: [
    "Science",
    "Space",
    "Wildlife",
    "Environment",
    "Neuroscience",
    "Physics",
    "Geology",
    "Paleontology",
    "Social sciences",
  ],
  sportsNews: [
    "Sports",
    "Soccer",
    "Cycling",
    "Motor sports",
    "Tennis",
    "Combat sports",
    "Basketball",
    "Baseball",
    "Football",
    "Sports betting",
    "Water sports",
    "Hockey",
    "Golf",
    "Cricket",
    "Rugby",
  ],
  technologyNews: [
    "Technology",
    "Mobile",
    "Energy",
    "Gaming",
    "Internet security",
    "Gadgets",
    "Virtual Reality",
    "Robotics",
  ],
  healthNews: ["Health", "Nutrition", "Public health"],
  educationNews: ["Education", "Jobs", "Higher education"],
  businessNews: [
    "Business",
    "Economy",
    "Personal Finance",
    "Finance",
    "Digital currencies",
  ],
  entertainmentNews: [
    "Entertainment",
    "Celebrities",
    "TV",
    "Music",
    "Movies",
    "Theater",
  ],
  lifestyleNews: [
    "Vehicles",
    "Arts & design",
    "Beauty",
    "Food",
    "Travel",
    "Shopping",
    "Home",
    "Outdoors",
    "Fashion",
  ],
};

const CategoryContainer = () => {
  const [selectedSubcategories, setSelectedSubcategories] = useState(new Set());
  const navigate = useNavigate();

  const handleNextClick = () => {
    navigate("/final");
  };

  const handleSubcategorySelect = (subcategory, isSelected) => {
    setSelectedSubcategories((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (isSelected) {
        newSelected.add(subcategory);
      } else {
        newSelected.delete(subcategory);
      }
      return newSelected;
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-neutral-100 to-neutral-200">
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-center text-4xl font-bold text-neutral-900 mb-4">
          Choose Your Interests
        </h1>
        <p className="text-center text-neutral-600 mb-6 text-lg">
          Select the topics you&apos;d like to see in your personalized news
          feed.
        </p>
        <div className="bg-info-100 border-l-4 border-info-500 text-info-700 p-4 mb-8 rounded-r-lg">
          <p className="flex items-center">
            <FaInfoCircle className="mr-2" />
            Selecting diverse topics ensures a well-rounded news experience.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
          {Object.entries(categories).map(([category, subcategories], idx) => (
            <Category
              key={idx}
              category={category}
              subcategories={subcategories}
              onSelectSubcategory={handleSubcategorySelect}
            />
          ))}
        </div>
        <div className="flex flex-col items-center mt-8">
          {selectedSubcategories.size > 0 ? (
            <button
              className="bg-success-500 hover:bg-success-600 text-white font-bold py-3 px-6 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 flex items-center"
              onClick={handleNextClick}
            >
              Continue to Your Feed <FaChevronRight className="ml-2" />
            </button>
          ) : (
            <p className="text-warning-700 bg-warning-100 px-4 py-2 rounded-full">
              Please select at least one topic to continue
            </p>
          )}
          <p className="mt-4 text-tertiary-700 flex items-center text-sm">
            <FaCheckCircle className="text-success-500 mr-2" />
            {selectedSubcategories.size}{" "}
            {selectedSubcategories.size === 1 ? "topic" : "topics"} selected
          </p>
        </div>
      </div>
    </div>
  );
};

export default CategoryContainer;
