import React from "react";
import {
  FaGlobe,
  FaUsers,
  FaMicrochip,
  FaUserTie,
  FaPaw,
  FaVoteYea,
  FaFutbol,
  FaChevronRight,
} from "react-icons/fa";

function Sidebar() {
  const suggestedTopics = [
    { icon: <FaMicrochip />, text: "Technology" },
    { icon: <FaUserTie />, text: "Celebrities" },
    { icon: <FaPaw />, text: "Animals" },
    { icon: <FaVoteYea />, text: "Politics" },
    { icon: <FaFutbol />, text: "Soccer" },
  ];

  return (
    <div className="w-full bg-white overflow-y-auto h-full flex flex-col">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-2xl font-bold text-gray-800 mb-2">
          TANTV News{" "}
          <span className="text-sm font-normal text-gray-500">(English)</span>
        </h2>
        <div className="flex items-center mt-3 text-sm text-gray-600">
          <FaUsers className="mr-2 text-gray-400" />
          <span>9 Followers</span>
        </div>
        <p className="mt-3 text-sm text-gray-600 leading-relaxed">
          Smart Journalism for the African Diaspora. We advance authentic
          narratives and empower our community.
        </p>
        <a
          href="https://www.tantvstudios.com"
          className="mt-4 inline-flex items-center text-blue-600 hover:text-blue-800 transition-colors duration-200"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaGlobe className="mr-2" />
          tantvstudios.com
          <FaChevronRight className="ml-1 text-xs" />
        </a>
      </div>
      <div className="p-6">
        <h3 className="text-lg font-semibold text-gray-700 mb-4">
          Suggested Topics
        </h3>
        <ul className="space-y-2">
          {suggestedTopics.map((item, index) => (
            <li
              key={index}
              className="flex items-center py-2 px-3 text-gray-700 hover:bg-gray-100 rounded-lg cursor-pointer transition duration-200 ease-in-out"
            >
              <span className="text-gray-400 mr-3">{item.icon}</span>
              <span>{item.text}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
