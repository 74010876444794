import React from "react";
import Sidebar from "./Sidebar";
import NewsFeed from "./NewsFeed";
import Head from "./Head";
import TrendingArticles from "./TrendingArticles";

const PublisherPage = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Head />
      <div className="flex-grow flex flex-col lg:flex-row">
        <aside className="w-full lg:w-64 bg-white shadow-md lg:border-r border-gray-200">
          <Sidebar />
        </aside>

        <main className="flex-grow overflow-hidden">
          <NewsFeed />
        </main>

        <aside className="w-full lg:w-80 px-4 lg:px-0 py-6 lg:py-0">
          <TrendingArticles />
        </aside>
      </div>
    </div>
  );
};

export default PublisherPage;
