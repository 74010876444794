import React, { useState } from "react";
import PublisherLogo1 from "../images/Logo1.png";
import { FaSearch, FaUser, FaCog, FaBell, FaBars } from "react-icons/fa";

const Head = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const navItems = [
    "Discover",
    "Following",
    "News",
    "US News",
    "World News",
    "Local",
    "Science",
    "Technology",
    "Crime",
    "Politics",
    "Entertainment",
    "Lifestyle",
    "Food & Drink",
  ];

  return (
    <header className="bg-white border-b border-neutral-200 w-full sticky top-0 z-50">
      <div className="px-4 py-3 mx-auto">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img src={PublisherLogo1} alt="Logo" className="h-8" />
            <span className="ml-2 text-2xl font-bold text-brand">syndex</span>
          </div>
          <div className="hidden md:flex flex-grow justify-center mx-4">
            <div className="relative w-full max-w-md">
              <input
                type="text"
                placeholder="Search the web"
                className="w-full py-2 px-4 border border-neutral-300 rounded-full focus:outline-none focus:ring-2 focus:ring-primary-500"
              />
              <FaSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-neutral-500" />
            </div>
          </div>
          <div className="hidden md:flex items-center space-x-4">
            <FaUser className="w-6 h-6 cursor-pointer text-neutral-600 hover:text-primary-600" />
            <FaCog className="w-6 h-6 cursor-pointer text-neutral-600 hover:text-primary-600" />
            <FaBell className="w-6 h-6 cursor-pointer text-neutral-600 hover:text-primary-600" />
          </div>
          <button className="md:hidden" onClick={toggleMenu}>
            <FaBars className="w-6 h-6 text-neutral-600" />
          </button>
        </div>
      </div>
      <div className="md:hidden">
        {isMenuOpen && (
          <div className="px-4 py-2 bg-neutral-100">
            <div className="mb-2">
              <input
                type="text"
                placeholder="Search the web"
                className="w-full py-2 px-4 border border-neutral-300 rounded-full focus:outline-none focus:ring-2 focus:ring-primary-500"
              />
            </div>
            <div className="flex justify-between mb-2">
              <FaUser className="w-6 h-6 cursor-pointer text-neutral-600" />
              <FaCog className="w-6 h-6 cursor-pointer text-neutral-600" />
              <FaBell className="w-6 h-6 cursor-pointer text-neutral-600" />
            </div>
          </div>
        )}
      </div>
      <nav className="px-4 py-2 overflow-x-auto bg-white">
        <div className="flex space-x-4 max-w-7xl mx-auto">
          {navItems.map((item) => (
            <button
              key={item}
              className="text-sm text-neutral-700 hover:text-primary-600 whitespace-nowrap"
            >
              {item}
            </button>
          ))}
          <button className="text-sm text-primary-600 border border-primary-600 px-3 py-1 rounded-full hover:bg-primary-50 whitespace-nowrap">
            Personalize
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Head;
