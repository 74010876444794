import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PopularCard from "./PopularCard";
import PublisherLogo1 from "../images/publisher1.png";
import PublisherLogo2 from "../images/publisher2.png";
import PublisherLogo3 from "../images/publisher3.png";

const SECTION_DATA = {
  regions: [
    {
      id: "region1",
      url: "https://www.google.com",
      urlToImage: PublisherLogo1,
      title: "Tantv",
      content:
        "Our mission is to organize the world's information and make it universally accessible and useful.",
    },
    {
      id: "region2",
      url: "https://www.starbucks.com/",
      urlToImage: PublisherLogo2,
      title: "The Scanner",
      content: "Download the app for half off during your first week.*",
    },
    {
      id: "region3",
      url: "https://www.google.com",
      urlToImage: PublisherLogo3,
      title: "Upscale",
      content: "Google is well known.",
    },
  ],
  publishers: [
    {
      id: "pub1",
      url: "https://www.starbucks.com/",
      urlToImage: PublisherLogo1,
      title: "Tantv",
      content: "Download the app for half off during your first week.*",
    },
    {
      id: "pub2",
      url: "https://www.google.com",
      urlToImage: PublisherLogo2,
      title: "The Scanner",
      content: "Google is well known.",
    },
    {
      id: "pub3",
      url: "https://www.google.com",
      urlToImage: PublisherLogo3,
      title: "Upscale",
      content: "Google is well known.",
    },
  ],
  creators: [
    {
      id: "creator1",
      url: "https://www.google.com",
      urlToImage: PublisherLogo1,
      title: "Tantv",
      content: "Google is well known.",
    },
    {
      id: "creator2",
      url: "https://www.google.com",
      urlToImage: PublisherLogo2,
      title: "The Scanner",
      content: "Google is well known.",
    },
    {
      id: "creator3",
      url: "https://www.starbucks.com/",
      urlToImage: PublisherLogo3,
      title: "Upscale",
      content: "Download the app for half off during your first week.",
    },
  ],
};

function PopularSection({ section, updateCount }) {
  const [selectedItems, setSelectedItems] = useState([]);
  const data = SECTION_DATA[section] || [];

  useEffect(() => {
    updateCount(selectedItems.length);
  }, [selectedItems, updateCount]);

  const handleSelection = (id) => {
    setSelectedItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  return (
    <section className="py-8">
      <h2 className="text-2xl font-bold text-primary-600 mb-6 capitalize border-b-2 border-primary-400 pb-2">
        {section}
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 justify-items-center">
        {data.map((item) => (
          <PopularCard
            key={item.id}
            {...item}
            isSelected={selectedItems.includes(item.id)}
            onSelect={() => handleSelection(item.id)}
          />
        ))}
      </div>
    </section>
  );
}

PopularSection.propTypes = {
  section: PropTypes.string.isRequired,
  updateCount: PropTypes.func.isRequired,
};

export default PopularSection;
