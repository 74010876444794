import React from "react";
import { FaHeart, FaComment, FaShare, FaTags } from "react-icons/fa";

const topics = [
  {
    id: 1,
    title: "The Future of Artificial Intelligence",
    image:
      "https://images.unsplash.com/photo-1485827404703-89b55fcc595e?auto=format&fit=crop&q=80&w=300&h=200",
    likes: 180,
    comments: 20,
    shares: 50,
    tag: "Technology",
  },
  {
    id: 2,
    title: "Sustainable Living: Small Changes, Big Impact",
    image:
      "https://images.unsplash.com/photo-1542601906990-b4d3fb778b09?auto=format&fit=crop&q=80&w=300&h=200",
    likes: 145,
    comments: 15,
    shares: 35,
    tag: "Environment",
  },
  {
    id: 3,
    title: "The Art of Mindfulness in a Busy World",
    image:
      "https://images.unsplash.com/photo-1508672019048-805c876b67e2?auto=format&fit=crop&q=80&w=300&h=200",
    likes: 210,
    comments: 25,
    shares: 60,
    tag: "Wellness",
  },
];

function YourTopics() {
  return (
    <section className="bg-white shadow-lg rounded-lg overflow-hidden mb-6 pb-2">
      <h2 className="text-xl font-bold px-6 py-4 bg-gray-50 border-b border-gray-200 flex items-center">
        <FaTags className="text-brand mr-2" />
        Your Topics
      </h2>
      <div className="divide-y divide-gray-200">
        {topics.map((topic) => (
          <div
            key={topic.id}
            className="p-4 hover:bg-gray-50 transition duration-300"
          >
            <div className="flex">
              <img
                src={topic.image}
                alt={topic.title}
                className="w-24 h-24 object-cover rounded-md mr-4"
              />
              <div className="flex-1">
                <h3 className="text-lg font-semibold mb-2 text-gray-800 hover:text-brand cursor-pointer">
                  {topic.title}
                </h3>
                <div className="flex items-center text-sm text-gray-500 mb-2">
                  <span className="bg-brand text-white px-2 py-1 rounded-full text-xs mr-2">
                    {topic.tag}
                  </span>
                </div>
                <div className="flex items-center text-sm text-gray-500 space-x-4">
                  <span className="flex items-center">
                    <FaHeart className="text-red-500 mr-1" />
                    {topic.likes}
                  </span>
                  <span className="flex items-center">
                    <FaComment className="text-blue-500 mr-1" />
                    {topic.comments}
                  </span>
                  <span className="flex items-center">
                    <FaShare className="text-green-500 mr-1" />
                    {topic.shares}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default YourTopics;
