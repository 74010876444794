// AudienceReachComponent.jsx
import React, { useState } from "react";

const AudienceReachComponent = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Subscribed with email:", email);
    setEmail("");
  };

  return (
    <section className="bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-4">
          Reach Your Audience Today
        </h2>
        <p className="text-lg text-gray-600 mb-8">
          Join our platform to create, publish, and advertise to a global
          audience.
        </p>

        <div className="flex flex-wrap justify-center gap-4 mb-8">
          {["Start Creating", "Start Publishing", "Launch Ad Campaign"].map(
            (text, index) => (
              <button
                key={index}
                className="bg-primary text-white px-4 py-2 rounded-lg font-semibold text-md shadow-md transition duration-300 ease-in-out transform hover:scale-105"
              >
                {text}
              </button>
            )
          )}
        </div>

        <div className="bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            Subscribe to Our Newsletter
          </h3>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col sm:flex-row gap-2"
          >
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="flex-grow px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand transition duration-200"
            />
            <button
              type="submit"
              className="bg-blue-600 text-white px-4 py-2 rounded-lg font-semibold shadow-md hover:bg-blue-700 transition duration-300 ease-in-out"
            >
              Subscribe
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default AudienceReachComponent;
