import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import PublisherComponent from "./components/PublisherComponent";
import JoinCreators from "./components/JoinCreators";
import Advertisers from "./components/Advertisers";
import AudienceReachComponent from "./components/AudienceReachComponent";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import CompanyInformationForm from "./components/CompanyInformationForm";
import ContentInformationForm from "./components/ContentInformationForm";
import SocialMediaProfilesForm from "./components/SocialMediaProfilesForm";
import PublisherPage from "./components/PublisherPage";
import CategoryContainer from "./components/CategoryContainer";
import Final from "./components/final";
import Fi from "./components/Fi";
import Layout from "./Layout";

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <main className="flex-grow flex flex-col">
                  <PublisherComponent />
                  <JoinCreators />
                  <Advertisers />
                  <AudienceReachComponent />
                </main>
              </>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/company" element={<CompanyInformationForm />} />
          <Route path="/content" element={<ContentInformationForm />} />
          <Route path="/profile" element={<SocialMediaProfilesForm />} />
          <Route path="/pub" element={<PublisherPage />} />
          <Route path="/publisher" element={<CategoryContainer />} />
          <Route path="/final" element={<Final />} />
          <Route path="/fi" element={<Fi />} />
          <Route
            path="*"
            element={
              <h1 className="text-center text-2xl mt-8">
                404 - Page Not Found
              </h1>
            }
          />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
