import React, { useState, useEffect } from "react";
import {
  FaSearch,
  FaSun,
  FaMoon,
  FaBell,
  FaUser,
  FaBars,
  FaTimes,
} from "react-icons/fa";
import { WiDaySunny } from "react-icons/wi";
import { MdAutoAwesome } from "react-icons/md";
import Logo1 from "../icons/Teal.png";

const themes = [
  { name: "light", icon: FaSun, color: "text-yellow-500" },
  { name: "dark", icon: FaMoon, color: "text-indigo-600" },
  { name: "auto", icon: MdAutoAwesome, color: "text-green-500" },
];

const Heead = () => {
  const [currentTheme, setCurrentTheme] = useState(0);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [temperature, setTemperature] = useState("69°F");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => setScrollPosition(window.pageYOffset);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTemperature(`${Math.floor(Math.random() * 30 + 60)}°F`);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const switchTheme = () => {
    setCurrentTheme((prevTheme) => (prevTheme + 1) % themes.length);
  };

  const ThemeIcon = themes[currentTheme].icon;

  return (
    <>
      <header
        className={`w-full fixed z-50 transition-all duration-500 ease-in-out ${
          scrollPosition > 50 || isMenuOpen
            ? "bg-white/90 backdrop-blur-md shadow-lg"
            : "bg-transparent"
        }`}
      >
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <button className="flex items-center" onClick={() => {}}>
                <span className="sr-only">SYNDEX NEWS</span>
                <img
                  src={Logo1}
                  alt="SYNDEX NEWS Logo"
                  className="h-16 w-auto object-contain"
                />
              </button>
            </div>

            <div className="hidden md:flex flex-1 items-center justify-center px-2">
              <div
                className={`w-full max-w-lg transition-all duration-300 ${
                  isSearchFocused ? "scale-105" : ""
                }`}
              >
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FaSearch
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    id="search"
                    name="search"
                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-full leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-brand focus:border-brand sm:text-sm transition duration-150 ease-in-out"
                    placeholder="Ask Syndex AI"
                    type="search"
                    onFocus={() => setIsSearchFocused(true)}
                    onBlur={() => setIsSearchFocused(false)}
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <div className="hidden lg:flex items-center space-x-1 text-sm text-gray-700">
                <WiDaySunny className="h-6 w-6 text-yellow-500" />
                <span>{temperature}</span>
              </div>
              <button
                onClick={switchTheme}
                className="bg-gray-200 p-2 rounded-full hover:bg-gray-300 transition duration-150 ease-in-out hidden sm:block"
                aria-label="Switch theme"
              >
                <ThemeIcon
                  className={`h-5 w-5 ${themes[currentTheme].color}`}
                />
              </button>
              <button
                className="bg-gray-200 p-2 rounded-full hover:bg-gray-300 transition duration-150 ease-in-out hidden sm:block"
                aria-label="Notifications"
              >
                <FaBell className="h-5 w-5 text-gray-600" />
              </button>
              <button
                className="bg-brand text-white p-2 rounded-full hover:bg-brand-dark transition duration-150 ease-in-out"
                aria-label="User profile"
              >
                <FaUser className="h-5 w-5" />
              </button>
              <button
                className="bg-gray-200 p-2 rounded-full hover:bg-gray-300 transition duration-150 ease-in-out sm:hidden"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                aria-label="Menu"
              >
                {isMenuOpen ? (
                  <FaTimes className="h-5 w-5 text-gray-600" />
                ) : (
                  <FaBars className="h-5 w-5 text-gray-600" />
                )}
              </button>
            </div>
          </div>

          {/* Mobile menu */}
          {isMenuOpen && (
            <div className="sm:hidden mt-2 pb-4">
              <div className="mb-4">
                <label htmlFor="mobile-search" className="sr-only">
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FaSearch
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    id="mobile-search"
                    name="mobile-search"
                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-full leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-brand focus:border-brand text-sm transition duration-150 ease-in-out"
                    placeholder="Ask Syndex AI"
                    type="search"
                  />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-1 text-sm text-gray-700">
                  <WiDaySunny className="h-6 w-6 text-yellow-500" />
                  <span>{temperature}</span>
                </div>
                <div className="flex items-center space-x-4">
                  <button
                    onClick={switchTheme}
                    className="bg-gray-200 p-2 rounded-full hover:bg-gray-300 transition duration-150 ease-in-out"
                    aria-label="Switch theme"
                  >
                    <ThemeIcon
                      className={`h-5 w-5 ${themes[currentTheme].color}`}
                    />
                  </button>
                  <button
                    className="bg-gray-200 p-2 rounded-full hover:bg-gray-300 transition duration-150 ease-in-out"
                    aria-label="Notifications"
                  >
                    <FaBell className="h-5 w-5 text-gray-600" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </header>
      {/* Overlay for pushing content down */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsMenuOpen(false)}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
        ></div>
      )}
      {/* Add padding to the main content to push it down when menu is open */}
      <div
        className={`transition-all duration-300 ${
          isMenuOpen ? "pt-48" : "pt-16"
        }`}
      >
        {/* Your main content goes here */}
      </div>
    </>
  );
};

export default Heead;
