import React from "react";
import GEImage from "../images/Advertisers/2048px-General_Motors__282021_29.svg.png";
import Native from "../images/Advertisers/Nativo_Logo.jpeg";
import Walmart from "../images/Advertisers/Walmart_Logos_LockupwTag_vert_blu_rgb.png";
import BlackOwnedMedia from "../images/Advertisers/Black_owned_media_Logo.png";
import Google from "../images/Advertisers/Google_Logo.png";
import Gilead_Logo from "../images/Advertisers/gilead-create-possible-svg.svg";

const advertisers = [
  { name: "Ad 1", image: GEImage },
  { name: "Ad 2", image: Native },
  { name: "Ad 3", image: Walmart },
  { name: "Ad 4", image: BlackOwnedMedia },
  { name: "Ad 5", image: Google },
  { name: "Ad 6", image: Gilead_Logo },
];

const Advertisers = () => {
  return (
    <section className="bg-white py-12 sm:py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-start mb-8">
          <h2 className="text-4xl text-black font-bold md:border-r md:border-gray-300 md:pr-5 md:mr-5 mb-4 md:mb-0 text-center md:text-left w-full md:w-auto">
            Join Other Advertisers
          </h2>
          <p className="text-base text-[#555] leading-[1.6] flex-1 text-center md:text-left">
            Reach local audiences on a global scale, attract more readers, boost
            traffic, and generate revenue for your publication.
          </p>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-6 md:gap-8">
          {advertisers.map((advertiser, index) => (
            <div
              key={index}
              className="aspect-square flex items-center justify-center p-4 rounded-lg"
            >
              <img
                src={advertiser.image}
                alt={advertiser.name}
                className="max-w-full max-h-full object-contain"
                style={{ maxWidth: "80%", maxHeight: "80%" }}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Advertisers;
