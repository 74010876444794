import React, { useState } from "react";
import {
  FaHome,
  FaCalendarAlt,
  FaBookmark,
  FaBell,
  FaUser,
  FaNewspaper,
  FaEllipsisH,
} from "react-icons/fa";

const menuItems = [
  { name: "Home", icon: FaHome },
  { name: "Events", icon: FaCalendarAlt },
  { name: "Bookmarked", icon: FaBookmark },
  { name: "Notification", icon: FaBell },
  { name: "Profile", icon: FaUser },
  { name: "AMAKA Editorial", icon: FaNewspaper },
  { name: "More", icon: FaEllipsisH },
];

const Sibar = () => {
  const [activeItem, setActiveItem] = useState("Home");

  return (
    <nav className="w-full h-full overflow-y-auto">
      <ul className="flex flex-col space-y-1 p-2">
        {menuItems.map((item) => (
          <li key={item.name} className="w-full">
            <button
              onClick={() => setActiveItem(item.name)}
              className={`flex items-center px-4 py-3 text-gray-700 transition duration-200 ease-in-out w-full rounded-lg
                ${
                  activeItem === item.name
                    ? "bg-brand-700 text-white"
                    : "hover:bg-gray-100 hover:text-brand-900"
                }`}
            >
              <item.icon
                className={`w-5 h-5 mr-3 ${
                  activeItem === item.name ? "text-white" : "text-gray-400"
                }`}
              />
              <span className="text-sm font-medium">{item.name}</span>
              {activeItem === item.name && (
                <span className="ml-auto">
                  <svg
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              )}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Sibar;
