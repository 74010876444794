import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaTag,
  FaLayerGroup,
  FaUsers,
  FaRss,
  FaCalendarAlt,
  FaGlobe,
} from "react-icons/fa";

const ContentInformationForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    brandName: "",
    mainContentCategory: "",
    contentAudience: "",
    rssFeedUrl: "",
    numberOfPosts: "",
    siteRequired: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    navigate("/profile");
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-white md:bg-neutral-100 px-4 py-8 font-sans">
      <form
        className="w-full max-w-2xl md:bg-white md:rounded-lg md:shadow-md p-4 md:p-8"
        onSubmit={handleSubmit}
      >
        <h2 className="text-2xl font-bold text-center text-neutral-800 mb-2">
          Content Information
        </h2>
        <p className="text-center text-neutral-600 mb-8">
          Please provide details about your content.
        </p>

        <div className="space-y-6">
          <div>
            <label
              htmlFor="brandName"
              className="block text-sm font-medium text-neutral-700 mb-1"
            >
              Brand/ Blog Name
            </label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-500">
                <FaTag />
              </span>
              <input
                type="text"
                id="brandName"
                name="brandName"
                value={formData.brandName}
                onChange={handleInputChange}
                placeholder="Enter your brand or blog name"
                className="w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand focus:border-brand"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="mainContentCategory"
              className="block text-sm font-medium text-neutral-700 mb-1"
            >
              Main Content Category
            </label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-orange-500">
                <FaLayerGroup />
              </span>
              <input
                type="text"
                id="mainContentCategory"
                name="mainContentCategory"
                value={formData.mainContentCategory}
                onChange={handleInputChange}
                placeholder="e.g., Technology, Fashion, Food"
                className="w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand focus:border-brand"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="contentAudience"
              className="block text-sm font-medium text-neutral-700 mb-1"
            >
              Content Audience
            </label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                <FaUsers />
              </span>
              <input
                type="text"
                id="contentAudience"
                name="contentAudience"
                value={formData.contentAudience}
                onChange={handleInputChange}
                placeholder="Describe your target audience"
                className="w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand focus:border-brand"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="rssFeedUrl"
              className="block text-sm font-medium text-neutral-700 mb-1"
            >
              RSS Feed URL
            </label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-orange-500">
                <FaRss />
              </span>
              <input
                type="url"
                id="rssFeedUrl"
                name="rssFeedUrl"
                value={formData.rssFeedUrl}
                onChange={handleInputChange}
                placeholder="https://example.com/feed"
                className="w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand focus:border-brand"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="numberOfPosts"
                className="block text-sm font-medium text-neutral-700 mb-1"
              >
                Posts Per Week
              </label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-500">
                  <FaCalendarAlt />
                </span>
                <input
                  type="number"
                  id="numberOfPosts"
                  name="numberOfPosts"
                  value={formData.numberOfPosts}
                  onChange={handleInputChange}
                  placeholder="e.g., 3"
                  min="0"
                  className="w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand focus:border-brand"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="siteRequired"
                className="block text-sm font-medium text-neutral-700 mb-1"
              >
                Site Required
              </label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-500">
                  <FaGlobe />
                </span>
                <select
                  id="siteRequired"
                  name="siteRequired"
                  value={formData.siteRequired}
                  onChange={handleInputChange}
                  className="w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand focus:border-brand appearance-none bg-white"
                >
                  <option value="">Select...</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <button
            type="submit"
            className="w-full bg-brand text-white py-3 px-4 rounded-md hover:bg-brand-dark focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2 transition duration-150 ease-in-out text-lg font-semibold"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContentInformationForm;
