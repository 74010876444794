import React from "react";
import { FaHeart, FaComment, FaShare, FaMapMarkerAlt } from "react-icons/fa";

const localStories = [
  {
    id: 1,
    title: "New Community Center Opens Its Doors",
    image:
      "https://images.unsplash.com/photo-1577896851231-70ef18881754?auto=format&fit=crop&q=80&w=1470&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    likes: 180,
    comments: 20,
    shares: 50,
    location: "Downtown",
  },
  {
    id: 2,
    title: "Local Farm's Organic Produce Gains Popularity",
    image:
      "https://images.unsplash.com/photo-1488459716781-31db52582fe9?auto=format&fit=crop&q=80&w=1470&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    likes: 245,
    comments: 32,
    shares: 78,
    location: "Greenville",
  },
  {
    id: 3,
    title: "City Council Approves New Park Development",
    image:
      "https://images.unsplash.com/photo-1492093502190-25c0c7b3094c?auto=format&fit=crop&q=80&w=1470&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    likes: 320,
    comments: 45,
    shares: 92,
    location: "City Hall",
  },
];

const LocalStories = () => (
  <section className="bg-white shadow-lg rounded-lg overflow-hidden mb-5 pb-2">
    <h2 className="text-2xl font-bold px-6 py-4 bg-gray-50 border-b border-gray-200 flex items-center">
      <FaMapMarkerAlt className="text-brand mr-2" />
      Local Stories
    </h2>
    <div className="divide-y divide-gray-200">
      {localStories.map(
        ({ id, title, image, likes, comments, shares, location }) => (
          <div
            key={id}
            className="flex p-4 hover:bg-gray-50 transition duration-300"
          >
            <img
              src={image}
              alt={title}
              className="w-24 h-24 object-cover rounded-md mr-4"
            />
            <div className="flex-1">
              <h3 className="text-lg font-semibold mb-1 text-gray-800 hover:text-brand cursor-pointer">
                {title}
              </h3>
              <p className="text-sm text-gray-600 mb-2 flex items-center">
                <FaMapMarkerAlt className="text-brand mr-1" />
                {location}
              </p>
              <div className="flex items-center text-sm text-gray-500 space-x-4">
                {[
                  [FaHeart, likes, "text-red-500"],
                  [FaComment, comments, "text-blue-500"],
                  [FaShare, shares, "text-green-500"],
                ].map(([Icon, value, color], index) => (
                  <span key={index} className="flex items-center">
                    <Icon className={`${color} mr-1`} />
                    {value.toLocaleString()}
                  </span>
                ))}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  </section>
);

export default LocalStories;
