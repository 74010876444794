import React, { useState } from "react";
import { FaRobot, FaImage, FaVideo, FaCalendarAlt } from "react-icons/fa";

const StartPost = () => {
  const [showOptions, setShowOptions] = useState(false);

  const options = [
    { icon: FaImage, text: "Photo" },
    { icon: FaVideo, text: "Video" },
    { icon: FaCalendarAlt, text: "Event" },
    { icon: FaRobot, text: "Write with AI", isSpecial: true },
  ];

  return (
    <div className="pt-2">
      <div className="relative flex items-center">
        <img
          src="https://i.pravatar.cc/40?img=68"
          alt="User Avatar"
          className="w-10 h-10 rounded-full mr-3"
        />
        <input
          type="text"
          placeholder="Start a post"
          className="w-full bg-transparent text-gray-700 border py-3 px-4 pr-12 rounded-full focus:outline-none focus:ring-2 focus:ring-brand focus:ring-opacity-50"
          onFocus={() => setShowOptions(true)}
          onBlur={() => setTimeout(() => setShowOptions(false), 200)}
        />
        <div className="absolute right-4 top-1/2 transform -translate-y-1/2 flex space-x-2">
          {showOptions &&
            options.map(({ icon: Icon, text, isSpecial }, index) => (
              <button
                key={index}
                className={`p-2 rounded-full ${
                  isSpecial
                    ? "text-brand hover:bg-brand hover:text-white"
                    : "text-gray-600 hover:bg-gray-200"
                } transition duration-300`}
                title={text}
              >
                <Icon />
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default StartPost;
