import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const Category = ({ category, subcategories, onSelectSubcategory }) => {
  const [selectedSubcategories, setSelectedSubcategories] = useState(new Set());
  const [isAllSelected, setIsAllSelected] = useState(false);

  useEffect(() => {
    setIsAllSelected(selectedSubcategories.size === subcategories.length);
  }, [selectedSubcategories, subcategories]);

  const handleSubcategoryClick = (subcategory) => {
    setSelectedSubcategories((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(subcategory)) {
        newSelected.delete(subcategory);
        onSelectSubcategory(subcategory, false);
      } else {
        newSelected.add(subcategory);
        onSelectSubcategory(subcategory, true);
      }
      return newSelected;
    });
  };

  const handleToggleAll = () => {
    if (isAllSelected) {
      setSelectedSubcategories(new Set());
      subcategories.forEach((subcat) => onSelectSubcategory(subcat, false));
    } else {
      const allSubcategories = new Set(subcategories);
      setSelectedSubcategories(allSubcategories);
      subcategories.forEach((subcat) => onSelectSubcategory(subcat, true));
    }
  };

  const formatCategoryName = (name) => {
    return name
      .replace(/([A-Z])/g, " $1")
      .trim()
      .replace(/News$/, "")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-300">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-neutral-800">
          {formatCategoryName(category)}
        </h2>
        <button
          className={`text-sm font-medium px-3 py-1 rounded-full transition-colors duration-200 ${
            isAllSelected
              ? "bg-primary-500 text-white hover:bg-primary-600"
              : "bg-neutral-200 text-neutral-700 hover:bg-neutral-300"
          }`}
          onClick={handleToggleAll}
        >
          {isAllSelected ? "Deselect All" : "Select All"}
        </button>
      </div>
      <div className="flex flex-wrap -m-1">
        {subcategories.map((subcat, idx) => (
          <div
            key={idx}
            className={`m-1 px-4 py-2 rounded-full text-sm font-medium cursor-pointer transition-all duration-200 ease-in-out ${
              selectedSubcategories.has(subcat)
                ? "bg-tertiary-500 text-white shadow-md transform scale-105"
                : "bg-neutral-100 text-neutral-700 hover:bg-neutral-200 hover:shadow"
            }`}
            onClick={() => handleSubcategoryClick(subcat)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubcategoryClick(subcat);
              }
            }}
            role="button"
            tabIndex={0}
          >
            {subcat}
          </div>
        ))}
      </div>
    </div>
  );
};

Category.propTypes = {
  category: PropTypes.string.isRequired,
  subcategories: PropTypes.array.isRequired,
  onSelectSubcategory: PropTypes.func.isRequired,
};

export default Category;
