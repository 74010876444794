import React from "react";
import TopStories from "./TopStories";
import LocalStories from "./LocalStories";
import ForYouTabs from "./ForYouTabs";
import StartPost from "./StartPost";
import YourTopics from "./YourTopics";

function All() {
  return (
    <div className="mx-auto px-4 sm:px-6">
      <div className="space-y-8">
        <StartPost />
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-8">
          <div className="xl:col-span-2 space-y-8">
            <TopStories />
            <ForYouTabs />
          </div>
          <div className="space-y-8">
            <YourTopics />
            <LocalStories />
          </div>
        </div>
      </div>
    </div>
  );
}

export default All;
