import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../icons/black & teal.png";

const Header = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleSignUpClick = () => {
    navigate("/signup");
  };

  // const handleClick = () => {
  //   navigate("/log");
  // };

  return (
    <header className="bg-gray-50 text-gray-800 shadow-md w-full">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-wrap justify-between items-center py-2">
          <div className="flex items-center space-x-2">
            <img src={Logo} alt="Syndex Logo" className="h-16 w-16" />
            {/* <span className="text-xl sm:text-2xl font-bold text-brand-500 font-logo uppercase tracking-wide">
              Syndex
            </span> */}
          </div>
          <div className="flex items-center space-x-2">
            <button
              className="bg-transparent text-blue-600 border-2 border-blue-600 px-3 py-1 sm:px-4 sm:py-2 rounded-full text-xs sm:text-sm font-bold uppercase tracking-wider hover:bg-blue-600 hover:text-white transition duration-300 ease-in-out transform hover:-translate-y-0.5"
              onClick={handleLoginClick}
            >
              Log in
            </button>
            <button
              className="bg-blue-600 text-white px-3 py-1 sm:px-4 sm:py-2 rounded-full text-xs sm:text-sm font-bold uppercase tracking-wider hover:bg-blue-700 transition duration-300 ease-in-out transform hover:-translate-y-0.5"
              onClick={handleSignUpClick}
            >
              Sign Up
            </button>
          </div>
        </div>
        <h1 className="text-center text-sm sm:text-base md:text-lg font-bold text-gray-700 uppercase tracking-wide my-4">
          Local News Information Aggregator & Creator Platform
        </h1>
        <div className="flex flex-wrap justify-center gap-2 sm:gap-4 pb-4">
          <button className="text-white px-3 py-1 sm:px-4 sm:py-2 rounded-md text-xs sm:text-sm font-bold uppercase tracking-wider bg-primary-900 hover:bg-primary-500 transition duration-300 ease-in-out transform hover:-translate-y-0.5 flex-grow sm:flex-grow-0">
            Creators
          </button>
          <a
            href="https://publisher.syndex.ai"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white px-3 py-1 sm:px-4 sm:py-2 rounded-md text-xs sm:text-sm font-bold uppercase tracking-wider bg-primary-900 hover:bg-primary-500 transition duration-300 ease-in-out transform hover:-translate-y-0.5 flex-grow sm:flex-grow-0"
          >
            Publishers
          </a>

          <button className="text-white px-3 py-1 sm:px-4 sm:py-2 rounded-md text-xs sm:text-sm font-bold uppercase tracking-wider bg-primary-900 hover:bg-primary-500 transition duration-300 ease-in-out transform hover:-translate-y-0.5 flex-grow sm:flex-grow-0">
            Advertisers
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
