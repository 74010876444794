// PublisherComponent.jsx
import React from "react";
import PublisherLogo1 from "../images/publisher1.png";
import PublisherLogo2 from "../images/publisher2.png";
import PublisherLogo3 from "../images/publisher3.png";
import PublisherLogo4 from "../images/publisher4.png";
import PublisherLogo5 from "../images/publisher5.png";
import PublisherLogo6 from "../images/publisher6.png";

const publishers = [
  { logo: PublisherLogo1 },
  { logo: PublisherLogo2 },
  { logo: PublisherLogo3 },
  { logo: PublisherLogo4 },
  { logo: PublisherLogo5 },
  { logo: PublisherLogo6 },
];

const PublisherComponent = () => {
  return (
    <section className="bg-white py-[60px] md:py-[40px]">
      <div className="max-w-[1200px] mx-auto px-5">
        <div className="flex flex-col md:flex-row justify-between items-start mb-8">
          <h2 className="text-4xl text-black font-bold md:border-r md:border-gray-300 md:pr-5 md:mr-5 mb-4 md:mb-0 text-center md:text-left w-full md:w-auto">
            Join Other Publishers
          </h2>
          <p className="text-base text-[#555] leading-[1.6] flex-1 text-center md:text-left">
            Reach local audiences on a global scale, attract more readers, boost
            traffic, and generate revenue for your publication.
          </p>
        </div>
        <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-[10px] md:gap-[15px] lg:gap-[20px] xl:gap-[30px] pt-5">
          {publishers.map((publisher, index) => (
            <div
              key={index}
              className="flex justify-center items-center p-[10px] transition-transform duration-300 ease-in-out hover:-translate-y-[5px]"
            >
              <div className="w-[80px] h-[80px] md:w-[100px] md:h-[100px] lg:w-[120px] lg:h-[120px] xl:w-[140px] xl:h-[140px] rounded-full overflow-hidden bg-white shadow-[0_4px_20px_rgba(0,0,0,0.1)] flex justify-center items-center">
                <img
                  src={publisher.logo}
                  alt={`Publisher ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PublisherComponent;
