import React, { useState } from "react";
import PopularSection from "./PopularSection";
import { useNavigate } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

const Final = () => {
  const navigate = useNavigate();
  const [totalSelectedCount, setTotalSelectedCount] = useState(0);

  const handleContinue = () => {
    navigate("/pub");
  };

  const updateTotalCount = (change) => {
    setTotalSelectedCount((prevCount) => Math.max(0, prevCount + change));
  };

  return (
    <div className="min-h-screen bg-neutral-50">
      <div className="container mx-auto px-4 py-12">
        <header className="text-center mb-10">
          <h1 className="text-4xl font-bold text-neutral-900 mb-4">
            Customize Your News Feed
          </h1>
          <p className="text-lg text-neutral-600">
            Follow your favorite publishers, regions, and creators
          </p>
        </header>

        <div className="space-y-10">
          <PopularSection section="publishers" updateCount={updateTotalCount} />
          <PopularSection section="regions" updateCount={updateTotalCount} />
          <PopularSection section="creators" updateCount={updateTotalCount} />
        </div>

        <div className="flex justify-center mt-12">
          {totalSelectedCount > 0 ? (
            <button
              className="bg-success-500 hover:bg-success-600 text-white font-bold py-3 px-6 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 flex items-center"
              onClick={handleContinue}
            >
              Continue to Your Feed <FaChevronRight className="ml-2" />
            </button>
          ) : (
            <button
              className="bg-neutral-300 text-neutral-700 font-bold py-3 px-6 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:bg-neutral-400"
              onClick={handleContinue}
            >
              Skip
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Final;
