import React from "react";
import Heead from "./Heead";
import Sibar from "./Sibar";
import All from "./All";
import RightSidebar from "./RightSidebar";

export default function Fi() {
  return (
    <div className="flex flex-col min-h-screen bg-neutral-200">
      <Heead className="flex-shrink-0 sticky top-0 left-0 right-0 z-50" />
      <div className="flex flex-col lg:flex-row flex-1 pt-2 px-4 lg:px-6 gap-4">
        <aside className="w-full lg:w-64 mb-4 lg:mb-0">
          <div className="lg:sticky lg:top-16 overflow-y-auto bg-white rounded-lg shadow-sm">
            <Sibar />
          </div>
        </aside>
        <div className="flex flex-col sm:flex-row flex-1 gap-4">
          <main className="flex-1 overflow-y-auto w-full sm:w-2/3 lg:w-3/4 bg-white rounded-lg shadow-sm p-4">
            <All />
          </main>
          <aside className="w-full sm:w-1/3 lg:w-1/4 flex-shrink-0 overflow-y-auto">
            <div className="lg:sticky lg:top">
              <RightSidebar />
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
}
