const articles = [
  {
    headline:
      "Joining Forces: How These Black Women in Media Inspire Inclusion",
    id: 1,
    publishedAt: "2 hours ago",
    topic: "Media",
    summary:
      "Discover how influential Black women are reshaping the media landscape and promoting diversity...",
    source: "The Atlantic",
    readTime: "5 min read",
  },
  {
    headline: "Best Deals on Rental Cars - Search and Save with KAYAK®",
    id: 2,
    publishedAt: "4 hours ago",
    topic: "Travel",
    summary:
      "Find out how you can save big on your next car rental with KAYAK's exclusive deals and search features...",
    source: "KAYAK",
    readTime: "3 min read",
  },
  {
    headline: "Ireland's Prime Minister Leo Varadkar Resigns",
    id: 3,
    publishedAt: "6 hours ago",
    topic: "Politics",
    summary:
      "Learn about the circumstances surrounding Leo Varadkar's resignation and its potential impact on Irish politics...",
    source: "BBC News",
    readTime: "4 min read",
  },
  {
    headline:
      "Strive Masiyiwa Spear Heads AI Solution to Businesses Across Africa",
    id: 4,
    publishedAt: "8 hours ago",
    topic: "Technology",
    summary:
      "Explore how Strive Masiyiwa's innovative AI solutions are transforming businesses across the African continent...",
    source: "TechCrunch",
    readTime: "6 min read",
  },
  {
    headline: "Climate Change: New Study Reveals Alarming Arctic Ice Melt Rate",
    id: 5,
    publishedAt: "10 hours ago",
    topic: "Environment",
    summary:
      "A recent study shows Arctic ice is melting at an unprecedented rate, raising concerns about global climate impact...",
    source: "National Geographic",
    readTime: "7 min read",
  },
  {
    headline:
      "Global Economic Forum Addresses Post-Pandemic Recovery Strategies",
    id: 6,
    publishedAt: "12 hours ago",
    topic: "Economy",
    summary:
      "World leaders and economists gather to discuss strategies for global economic recovery in the post-pandemic era...",
    source: "Financial Times",
    readTime: "5 min read",
  },
];

import React from "react";
import {
  FaClock,
  FaBookmark,
  FaShare,
  FaExternalLinkAlt,
} from "react-icons/fa";

export default function NewsFeed() {
  return (
    <div className="w-full overflow-y-auto bg-white p-4">
      <h2 className="text-3xl font-bold mb-4 text-primary-700 border-b border-primary-200 pb-2">
        Today&rsquo;s Top Stories
      </h2>
      <div className="space-y-4">
        {articles.map((article) => (
          <div
            key={article.id}
            className="p-3 rounded-lg transition-all duration-300 hover:bg-neutral-100 hover:shadow-sm"
          >
            <header className="flex justify-between items-center mb-2">
              <span className="px-2 py-0.5 bg-brand-100 text-brand-700 text-xs font-semibold rounded-full">
                {article.topic}
              </span>
              <div className="flex space-x-2 text-neutral-400">
                <button
                  className="hover:text-brand-500 transition-colors duration-200"
                  aria-label="Bookmark"
                >
                  <FaBookmark className="w-3 h-3" />
                </button>
                <button
                  className="hover:text-primary-500 transition-colors duration-200"
                  aria-label="Share"
                >
                  <FaShare className="w-3 h-3" />
                </button>
              </div>
            </header>
            <h3 className="text-lg font-semibold text-primary-800 mb-1 leading-tight hover:text-primary-600 transition-colors duration-200">
              {article.headline}
            </h3>
            <p className="text-neutral-600 text-sm mb-2 line-clamp-2">
              {article.summary}
            </p>
            <footer className="flex justify-between items-center text-xs text-neutral-500">
              <div className="flex items-center space-x-3">
                <span className="flex items-center">
                  <FaClock className="mr-1 w-2.5 h-2.5 text-neutral-400" />
                  {article.publishedAt}
                </span>
                <span className="text-primary-500 font-medium">
                  {article.readTime}
                </span>
              </div>
              <button
                className="flex items-center text-neutral-700 hover:text-brand-700 transition-colors duration-200"
                aria-label={`Read more on ${article.source}`}
                onClick={(e) => e.preventDefault()}
              >
                read more
                <FaExternalLinkAlt className="ml-1 w-2.5 h-2.5" />
              </button>
            </footer>
          </div>
        ))}
      </div>
    </div>
  );
}
