import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaTiktok,
  FaYoutube,
  FaLinkedinIn,
  FaPinterestP,
} from "react-icons/fa";

const SocialMediaProfilesForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    twitter: "",
    instagram: "",
    facebook: "",
    tiktok: "",
    youtube: "",
    linkedin: "",
    pinterest: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    navigate("/fi");
  };

  const socialMediaPlatforms = [
    {
      name: "twitter",
      icon: <FaTwitter />,
      label: "Twitter",
      color: "text-[#1DA1F2]",
    },
    {
      name: "instagram",
      icon: <FaInstagram />,
      label: "Instagram",
      color: "text-[#E1306C]",
    },
    {
      name: "facebook",
      icon: <FaFacebookF />,
      label: "Facebook",
      color: "text-[#4267B2]",
    },
    {
      name: "tiktok",
      icon: <FaTiktok />,
      label: "TikTok",
      color: "text-black",
    },
    {
      name: "youtube",
      icon: <FaYoutube />,
      label: "YouTube",
      color: "text-[#FF0000]",
    },
    {
      name: "linkedin",
      icon: <FaLinkedinIn />,
      label: "LinkedIn",
      color: "text-[#0077B5]",
    },
    {
      name: "pinterest",
      icon: <FaPinterestP />,
      label: "Pinterest",
      color: "text-[#E60023]",
    },
  ];

  return (
    <div className="flex justify-center items-center min-h-screen bg-white md:bg-neutral-100 px-4 py-8 font-sans">
      <form
        className="w-full max-w-2xl md:bg-white md:rounded-lg md:shadow-md p-4 md:p-8"
        onSubmit={handleSubmit}
      >
        <h2 className="text-2xl font-bold text-center text-neutral-800 mb-2">
          Social Media Profiles
        </h2>
        <p className="text-center text-neutral-600 mb-8">
          Connect your social media accounts to enhance your profile.
        </p>

        <div className="space-y-6">
          {socialMediaPlatforms.map((social) => (
            <div key={social.name}>
              <label htmlFor={social.name} className="sr-only">
                {social.label} URL
              </label>
              <div className="relative">
                <span
                  className={`absolute inset-y-0 left-0 flex items-center pl-3 ${social.color}`}
                >
                  {social.icon}
                </span>
                <input
                  type="url"
                  id={social.name}
                  name={social.name}
                  value={formData[social.name]}
                  onChange={handleInputChange}
                  placeholder={`${social.label} URL`}
                  className="w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand focus:border-brand"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="mt-8">
          <button
            type="submit"
            className="w-full bg-brand text-white py-3 px-4 rounded-md hover:bg-brand-dark focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2 transition duration-150 ease-in-out text-lg font-semibold"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default SocialMediaProfilesForm;
