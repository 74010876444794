import React from "react";
import { FaNewspaper, FaExternalLinkAlt, FaClock } from "react-icons/fa";

function TrendingArticles() {
  const topStories = [
    {
      id: 1,
      source: "NBC News",
      title: "Appeals court seems skeptical of Texas abortion law challenge",
      time: "2h ago",
      url: "#",
    },
    {
      id: 2,
      source: "USA Today",
      title:
        "Fani Willis case: Judge to rule on disqualification in Trump election fraud case",
      time: "4h ago",
      url: "#",
    },
    {
      id: 3,
      source: "NBC News",
      title: "Trump struggles to secure bond for fraud case appeal",
      time: "5h ago",
      url: "#",
    },
  ];

  return (
    <div className="bg-white border border-gray-200 p-4">
      <h3 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
        <FaNewspaper className="mr-2 text-primary-500" />
        Top Stories
      </h3>
      <ul className="space-y-4">
        {topStories.map((story) => (
          <li
            key={story.id}
            className="border-b border-gray-100 pb-4 last:border-b-0 last:pb-0"
          >
            <a
              href={story.url}
              className="block hover:bg-gray-50 transition duration-150 ease-in-out rounded p-2"
            >
              <div className="flex justify-between items-center mb-1">
                <span className="font-medium text-primary-600 text-sm">
                  {story.source}
                </span>
                <span className="text-xs text-gray-500 flex items-center">
                  <FaClock className="mr-1" /> {story.time}
                </span>
              </div>
              <p className="text-gray-800 text-sm font-medium">{story.title}</p>
              <div className="text-xs text-primary-500 font-medium flex items-center mt-2">
                Read more <FaExternalLinkAlt className="ml-1" />
              </div>
            </a>
          </li>
        ))}
      </ul>
      <button className="mt-4 w-full bg-primary-500 text-white py-2 rounded-full hover:bg-primary-600 transition duration-300 ease-in-out flex items-center justify-center">
        <span className="text-sm font-medium">View All Top Stories</span>
        <FaExternalLinkAlt className="ml-2" />
      </button>
    </div>
  );
}

export default TrendingArticles;
