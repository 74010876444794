const tabContent = {
  "For You": [
    {
      id: 1,
      title: "The Future of AI in Healthcare",
      excerpt:
        "Exploring how AI is revolutionizing medical diagnoses and treatment plans.",
      image:
        "https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
      author: "Dr. Jane Smith",
      authorAvatar: "https://i.pravatar.cc/150?img=1",
      readTime: "7 min read",
      views: 1520,
      comments: 48,
      likes: 237,
      tags: ["AI", "Healthcare", "Technology"],
      publishDate: "2023-09-15",
      premium: true,
    },
    {
      id: 2,
      title: "Sustainable Urban Planning",
      excerpt:
        "Innovative approaches to creating eco-friendly and livable urban spaces.",
      image:
        "https://images.unsplash.com/photo-1518005020951-eccb494ad742?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
      author: "Alex Green",
      authorAvatar: "https://i.pravatar.cc/150?img=2",
      readTime: "5 min read",
      views: 982,
      comments: 31,
      likes: 185,
      tags: ["Urban Planning", "Sustainability", "Environment"],
      publishDate: "2023-09-18",
      premium: false,
    },
    {
      id: 3,
      title: "The Rise of Quantum Computing",
      excerpt:
        "Understanding the potential and challenges of quantum technology.",
      image:
        "https://images.unsplash.com/photo-1635070041078-e363dbe005cb?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
      author: "Prof. Michael Chen",
      authorAvatar: "https://i.pravatar.cc/150?img=3",
      readTime: "10 min read",
      views: 2105,
      comments: 76,
      likes: 412,
      tags: ["Quantum Computing", "Technology", "Science"],
      publishDate: "2023-09-20",
      premium: true,
    },
    {
      id: 4,
      title: "Mindfulness in the Digital Age",
      excerpt:
        "Techniques for maintaining mental well-being in a connected world.",
      image:
        "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
      author: "Emma Watson",
      authorAvatar: "https://i.pravatar.cc/150?img=4",
      readTime: "6 min read",
      views: 1750,
      comments: 93,
      likes: 528,
      tags: ["Mindfulness", "Mental Health", "Digital Wellness"],
      publishDate: "2023-09-22",
      premium: false,
    },
    {
      id: 5,
      title: "The Future of Renewable Energy",
      excerpt:
        "Exploring innovative solutions for sustainable power generation.",
      image:
        "https://images.unsplash.com/photo-1508514177221-188b1cf16e9d?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
      author: "Dr. Sarah Johnson",
      authorAvatar: "https://i.pravatar.cc/150?img=5",
      readTime: "8 min read",
      views: 3102,
      comments: 127,
      likes: 689,
      tags: ["Renewable Energy", "Sustainability", "Climate Change"],
      publishDate: "2023-09-25",
      premium: true,
    },
    {
      id: 6,
      title: "The Art of Effective Communication",
      excerpt:
        "Mastering the skills of clear and impactful communication in various contexts.",
      image:
        "https://images.unsplash.com/photo-1529156069898-49953e39b3ac?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
      author: "Thomas Anderson",
      authorAvatar: "https://i.pravatar.cc/150?img=6",
      readTime: "5 min read",
      views: 1876,
      comments: 64,
      likes: 352,
      tags: ["Communication", "Personal Development", "Leadership"],
      publishDate: "2023-09-27",
      premium: false,
    },
  ],
  Technology: [
    {
      id: 7,
      title: "5G Revolution: Transforming Connectivity",
      excerpt:
        "How 5G technology is reshaping our digital landscape and its potential impacts.",
      image:
        "https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
      author: "Tech Guru",
      authorAvatar: "https://i.pravatar.cc/150?img=7",
      readTime: "9 min read",
      views: 2450,
      comments: 87,
      likes: 563,
      tags: ["5G", "Technology", "Connectivity"],
      publishDate: "2023-09-28",
      premium: true,
    },
    {
      id: 8,
      title: "The Impact of Blockchain on Finance",
      excerpt:
        "Exploring how blockchain technology is changing the financial landscape.",
      image:
        "https://images.unsplash.com/photo-1581091870620-2f5c5e3d1d7b?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
      author: "Finance Expert",
      authorAvatar: "https://i.pravatar.cc/150?img=8",
      readTime: "6 min read",
      views: 1500,
      comments: 45,
      likes: 300,
      tags: ["Blockchain", "Finance", "Innovation"],
      publishDate: "2023-09-29",
      premium: false,
    },
    {
      id: 9,
      title: "Cybersecurity in the Age of IoT",
      excerpt:
        "Understanding the security challenges posed by the Internet of Things.",
      image:
        "https://images.unsplash.com/photo-1518709268806-2b8a3f4e0e6e?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
      author: "Cybersecurity Analyst",
      authorAvatar: "https://i.pravatar.cc/150?img=9",
      readTime: "8 min read",
      views: 980,
      comments: 30,
      likes: 150,
      tags: ["Cybersecurity", "IoT", "Technology"],
      publishDate: "2023-09-30",
      premium: true,
    },
    {
      id: 10,
      title: "The Evolution of Smart Homes",
      excerpt: "How smart technology is transforming the way we live.",
      image:
        "https://images.unsplash.com/photo-1585152551337-4b4b0b8b3d3a?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
      author: "Home Tech Specialist",
      authorAvatar: "https://i.pravatar.cc/150?img=10",
      readTime: "5 min read",
      views: 1200,
      comments: 20,
      likes: 100,
      tags: ["Smart Home", "Technology", "Innovation"],
      publishDate: "2023-10-01",
      premium: false,
    },
  ],
  "Health & Wellness": [
    {
      id: 11,
      title: "The Science of Sleep",
      excerpt:
        "Understanding the importance of quality sleep and tips for better rest.",
      image:
        "https://images.unsplash.com/photo-1515894203077-2ce936e3f9be?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
      author: "Dr. Sleep Expert",
      authorAvatar: "https://i.pravatar.cc/150?img=11",
      readTime: "7 min read",
      views: 1980,
      comments: 72,
      likes: 421,
      tags: ["Sleep", "Health", "Wellness"],
      publishDate: "2023-10-02",
      premium: false,
    },
    {
      id: 12,
      title: "Nutrition Myths Debunked",
      excerpt: "Separating fact from fiction in the world of nutrition.",
      image:
        "https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
      author: "Nutritionist Jane",
      authorAvatar: "https://i.pravatar.cc/150?img=12",
      readTime: "6 min read",
      views: 1500,
      comments: 50,
      likes: 300,
      tags: ["Nutrition", "Health", "Diet"],
      publishDate: "2023-10-03",
      premium: true,
    },
    {
      id: 13,
      title: "Yoga for Stress Relief",
      excerpt: "How yoga practices can help alleviate stress and anxiety.",
      image:
        "https://images.unsplash.com/photo-1521747116042-5a810fda9664?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
      author: "Yoga Instructor",
      authorAvatar: "https://i.pravatar.cc/150?img=13",
      readTime: "5 min read",
      views: 1300,
      comments: 40,
      likes: 200,
      tags: ["Yoga", "Wellness", "Stress Relief"],
      publishDate: "2023-10-04",
      premium: false,
    },
    {
      id: 14,
      title: "Mental Health Awareness",
      excerpt:
        "Understanding the importance of mental health and how to seek help.",
      image:
        "https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
      author: "Mental Health Advocate",
      authorAvatar: "https://i.pravatar.cc/150?img=14",
      readTime: "8 min read",
      views: 2500,
      comments: 100,
      likes: 500,
      tags: ["Mental Health", "Awareness", "Support"],
      publishDate: "2023-10-05",
      premium: true,
    },
  ],
};

import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  FaChevronLeft,
  FaChevronRight,
  FaEye,
  FaComments,
  FaHeart,
  FaClock,
  FaStar,
} from "react-icons/fa";

const PostCard = ({ post }) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col">
      <div className="relative h-40 sm:h-36">
        <img
          src={post.image}
          alt={post.title}
          className="w-full h-full object-cover"
        />
        {post.premium && (
          <span className="absolute top-2 right-2 bg-yellow-400 text-xs font-bold px-2 py-1 rounded-full flex items-center">
            <FaStar className="text-white mr-1" />
            PRO
          </span>
        )}
      </div>
      <div className="p-4 flex flex-col flex-grow">
        <h3 className="text-sm font-semibold text-gray-800 line-clamp-2 mb-2">
          {post.title}
        </h3>
        <p className="text-xs text-gray-600 line-clamp-3 mb-2">
          {post.excerpt}
        </p>
        <div className="mt-auto">
          <div className="flex items-center text-xs text-gray-500 mb-2">
            <FaClock className="mr-1 text-blue-500" />
            <span>{post.readTime}</span>
          </div>
          <div className="flex justify-between items-center text-xs text-gray-500">
            <span className="flex items-center">
              <FaEye className="mr-1 text-green-500" />
              {post.views}
            </span>
            <span className="flex items-center">
              <FaComments className="mr-1 text-indigo-500" />
              {post.comments}
            </span>
            <span className="flex items-center">
              <FaHeart className="mr-1 text-red-500" />
              {post.likes}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

PostCard.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    readTime: PropTypes.string.isRequired,
    views: PropTypes.number.isRequired,
    comments: PropTypes.number.isRequired,
    likes: PropTypes.number.isRequired,
    premium: PropTypes.bool.isRequired,
  }).isRequired,
};

function ForYouTabs() {
  const [selectedTab, setSelectedTab] = useState("For You");
  const tabContainerRef = useRef(null);

  const scrollLeft = () => {
    tabContainerRef.current.scrollBy({
      top: 0,
      left: -200,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    tabContainerRef.current.scrollBy({ top: 0, left: 200, behavior: "smooth" });
  };

  const tabs = Object.keys(tabContent);

  return (
    <div className="mb-6 pb-2">
      <div className="relative p-2 mb-4 z-20">
        <div className="flex items-center">
          <button
            className="p-2 bg-white rounded-full shadow-md text-gray-600 hover:text-gray-800 focus:outline-none"
            onClick={scrollLeft}
          >
            <FaChevronLeft />
          </button>
          <div
            ref={tabContainerRef}
            className="flex-1 overflow-x-auto scrollbar-hide mx-2 whitespace-nowrap p-2"
          >
            {tabs.map((tab) => (
              <button
                key={tab}
                className={`px-4 py-2 mx-1 text-sm font-medium rounded-full transition duration-300 ease-in-out ${
                  selectedTab === tab
                    ? "bg-blue-500 text-white"
                    : "text-gray-700 hover:text-blue-500 hover:bg-gray-100"
                }`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
          <button
            className="p-2 bg-white rounded-full shadow-md text-gray-600 hover:text-gray-800 focus:outline-none"
            onClick={scrollRight}
          >
            <FaChevronRight />
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {tabContent[selectedTab].map((item) => (
          <PostCard key={item.id} post={item} />
        ))}
      </div>
    </div>
  );
}

export default ForYouTabs;
