import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaBuilding, FaGlobe, FaMapMarkerAlt, FaFlag } from "react-icons/fa";

const CompanyInformationForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    companyName: "",
    companyWebsite: "",
    companyAddress: "",
    addressLine1: "",
    addressLine2: "",
    state: "NT",
    zip: "",
    country: "United States",
  });

  const states = ["NT", "NSW", "QLD", "SA", "TAS", "VIC", "WA"];
  const countries = [
    "United States",
    "Canada",
    "United Kingdom",
    "Australia",
    "Germany",
    "France",
    "Japan",
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    navigate("/content");
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-white md:bg-neutral-100 px-4 py-8 font-sans">
      <form
        className="w-full max-w-2xl md:bg-white md:rounded-lg md:shadow-md p-4 md:p-8"
        onSubmit={handleSubmit}
      >
        <h2 className="text-2xl font-bold text-center text-neutral-800 mb-2">
          Company Information
        </h2>
        <p className="text-center text-neutral-600 mb-8">
          Please provide your company details below.
        </p>

        <div className="space-y-6">
          <div>
            <label htmlFor="companyName" className="sr-only">
              Company Name
            </label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-neutral-500">
                <FaBuilding />
              </span>
              <input
                type="text"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
                placeholder="Company Name (N/A if not applicable)"
                className="w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand focus:border-brand"
              />
            </div>
          </div>

          <div>
            <label htmlFor="companyWebsite" className="sr-only">
              Company Website
            </label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-neutral-500">
                <FaGlobe />
              </span>
              <input
                type="url"
                id="companyWebsite"
                name="companyWebsite"
                value={formData.companyWebsite}
                onChange={handleInputChange}
                placeholder="Company Website"
                className="w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand focus:border-brand"
              />
            </div>
          </div>

          <div>
            <label htmlFor="companyAddress" className="sr-only">
              Company Address
            </label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-neutral-500">
                <FaMapMarkerAlt />
              </span>
              <input
                type="text"
                id="companyAddress"
                name="companyAddress"
                value={formData.companyAddress}
                onChange={handleInputChange}
                placeholder="Company Address (optional)"
                className="w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand focus:border-brand"
              />
            </div>
          </div>

          <div>
            <label htmlFor="addressLine1" className="sr-only">
              Address Line 1
            </label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-neutral-500">
                <FaMapMarkerAlt />
              </span>
              <input
                type="text"
                id="addressLine1"
                name="addressLine1"
                value={formData.addressLine1}
                onChange={handleInputChange}
                placeholder="Address Line 1"
                className="w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand focus:border-brand"
              />
            </div>
          </div>

          <div>
            <label htmlFor="addressLine2" className="sr-only">
              Address Line 2
            </label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-neutral-500">
                <FaMapMarkerAlt />
              </span>
              <input
                type="text"
                id="addressLine2"
                name="addressLine2"
                value={formData.addressLine2}
                onChange={handleInputChange}
                placeholder="Address Line 2"
                className="w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand focus:border-brand"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="state"
                className="block text-sm font-medium text-neutral-700 mb-1"
              >
                State
              </label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-neutral-500">
                  <FaMapMarkerAlt />
                </span>
                <select
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  className="w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand focus:border-brand appearance-none bg-white"
                >
                  {states.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label
                htmlFor="zip"
                className="block text-sm font-medium text-neutral-700 mb-1"
              >
                ZIP
              </label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-neutral-500">
                  <FaMapMarkerAlt />
                </span>
                <input
                  type="text"
                  id="zip"
                  name="zip"
                  value={formData.zip}
                  onChange={handleInputChange}
                  placeholder="ZIP"
                  className="w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand focus:border-brand"
                />
              </div>
            </div>
          </div>

          <div>
            <label
              htmlFor="country"
              className="block text-sm font-medium text-neutral-700 mb-1"
            >
              Country
            </label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-neutral-500">
                <FaFlag />
              </span>
              <select
                id="country"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                className="w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand focus:border-brand appearance-none bg-white"
              >
                {countries.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <button
            type="submit"
            className="w-full bg-brand text-white py-3 px-4 rounded-md hover:bg-brand-dark focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2 transition duration-150 ease-in-out text-lg font-semibold"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default CompanyInformationForm;
