import React from "react";
import {
  FaMicrophone,
  FaMapMarkerAlt,
  FaFireAlt,
  FaUserPlus,
} from "react-icons/fa";
import PropTypes from "prop-types";

function SectionWrapper({ title, icon: Icon, children }) {
  return (
    <section className="bg-white rounded-lg shadow-sm mb-4">
      <h2 className="text-lg font-semibold p-3 bg-gray-50 rounded-t-lg flex items-center">
        <Icon className="text-brand mr-2" />
        {title}
      </h2>
      <div className="p-3">{children}</div>
    </section>
  );
}

function LivePodcasts({ items }) {
  return (
    <SectionWrapper title="Live on Podcasts" icon={FaMicrophone}>
      <ul className="space-y-2">
        {items.map((item, index) => (
          <li key={index}>
            <p className="text-sm text-gray-700 hover:text-brand cursor-pointer transition duration-300 ease-in-out line-clamp-2">
              {item}
            </p>
          </li>
        ))}
      </ul>
    </SectionWrapper>
  );
}

function SuggestedForYou({ items }) {
  return (
    <SectionWrapper title="Suggested for You" icon={FaUserPlus}>
      <ul className="space-y-3">
        {items.map((item, index) => (
          <li key={index}>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src={item.avatar}
                  alt={item.name}
                  className="w-10 h-10 rounded-full mr-3 border-2 border-gray-200"
                />
                <div>
                  <p className="font-medium text-sm">{item.name}</p>
                  <p className="text-gray-500 text-xs">{item.handle}</p>
                </div>
              </div>
              <button className="bg-transparent text-brand border border-brand px-3 py-1 rounded-full text-xs hover:bg-brand hover:text-white transition duration-300 ease-in-out">
                Follow
              </button>
            </div>
          </li>
        ))}
      </ul>
    </SectionWrapper>
  );
}

function LocalNews({ items }) {
  return (
    <SectionWrapper title="Local News" icon={FaMapMarkerAlt}>
      <ul className="space-y-2">
        {items.map((item, index) => (
          <li key={index}>
            <p className="text-sm text-gray-700 hover:text-brand cursor-pointer transition duration-300 ease-in-out line-clamp-2">
              {item}
            </p>
          </li>
        ))}
      </ul>
    </SectionWrapper>
  );
}

function WhatsHappening({ items }) {
  return (
    <SectionWrapper title="What's Happening" icon={FaFireAlt}>
      <ul className="space-y-2">
        {items.map((item, index) => (
          <li key={index} className="flex items-center">
            <span className="w-2 h-2 bg-brand rounded-full mr-2"></span>
            <p className="text-sm text-gray-700 hover:text-brand cursor-pointer transition duration-300 ease-in-out">
              {item}
            </p>
          </li>
        ))}
      </ul>
    </SectionWrapper>
  );
}

SectionWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  children: PropTypes.node.isRequired,
};

LivePodcasts.propTypes = {
  items: PropTypes.array.isRequired,
};
SuggestedForYou.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      handle: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
    })
  ).isRequired,
};
LocalNews.propTypes = {
  items: PropTypes.array.isRequired,
};
WhatsHappening.propTypes = {
  items: PropTypes.array.isRequired,
};

function RightSidebar() {
  const sections = [
    {
      title: "Live on Podcasts",
      items: [
        "Tesla's profits sink as the company struggles with cooling demand",
        "How Africans in the U.S Diaspora Can Celebrate Juneteenth",
        "Here is where live audio conversations about any news or articles appear",
      ],
    },
    {
      title: "Suggested for You",
      items: [
        {
          name: "Folashade Omish",
          handle: "@folashade",
          avatar: "https://i.pravatar.cc/40?img=1",
        },
        {
          name: "Oluwanifemi Omo",
          handle: "@oluwanifemi",
          avatar: "https://i.pravatar.cc/40?img=2",
        },
        {
          name: "Anwiva Buachie",
          handle: "@anwiva",
          avatar: "https://i.pravatar.cc/40?img=3",
        },
        {
          name: "Onxeii",
          handle: "@Onxeii",
          avatar: "https://i.pravatar.cc/40?img=4",
        },
      ],
    },
    {
      title: "Local News",
      items: [
        "Terence Blanchard, composer of Spike Lee movie masterpieces, brings opera",
        "Matt About Town: Meet 17-year-old Page, who used his Make-A-Wish experience to",
        "Navigating the Summer Shutdown: Metro's Red Line Bus Shuttle Plan",
      ],
    },
    {
      title: "What's Happening",
      items: [
        "Diamondbacks at Cardinals",
        "Morgan Freeman",
        "Neosporin",
        "McConnell",
        "Crackhead Barney",
      ],
    },
  ];

  return (
    <aside className="w-full overflow-hidden">
      <div className="lg:block space-y-4">
        <LivePodcasts items={sections[0].items} />
        <SuggestedForYou items={sections[1].items} />
        <LocalNews items={sections[2].items} />
        <WhatsHappening items={sections[3].items} />
      </div>
    </aside>
  );
}

export default RightSidebar;
