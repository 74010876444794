import React from "react";
import { FaHeart, FaComment, FaShare } from "react-icons/fa";

const stories = [
  {
    id: 1,
    title: "Breaking News: Major Scientific Discovery Announced",
    image:
      "https://images.unsplash.com/photo-1575468130861-a5295155e1d1?q=80&w=3600&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    likes: 1292,
    comments: 340,
    shares: 750,
  },
  {
    id: 2,
    title: "Tech Giant Unveils Revolutionary New Product",
    image:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDF8fHRlY2h8ZW58MHx8fHwxNjYzNjY5Njg0&ixlib=rb-1.2.1&q=80&w=400",
    likes: 892,
    comments: 210,
    shares: 430,
  },
  {
    id: 3,
    title: "Global Climate Summit Reaches Landmark Agreement",
    image:
      "https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDEwfHxjbGltYXRlJTIwc3VtbWFpdHxlbnwwfHx8fDE2NjM2Njk2ODQ&ixlib=rb-1.2.1&q=80&w=400",
    likes: 1567,
    comments: 456,
    shares: 982,
  },
];

const TopStories = () => (
  <section className="bg-white shadow-lg rounded-lg overflow-hidden mb-5">
    <h2 className="text-2xl font-bold px-6 py-4 bg-gray-50 border-b border-gray-200">
      Top Stories
    </h2>
    <div className="divide-y divide-gray-200">
      {stories.map(({ id, title, image, likes, comments, shares }) => (
        <div
          key={id}
          className="flex p-4 hover:bg-gray-50 transition duration-300"
        >
          <img
            src={image}
            alt={title}
            className="w-24 h-24 object-cover rounded-md mr-4"
          />
          <div className="flex-1">
            <h3 className="text-lg font-semibold mb-2 text-gray-800 hover:text-brand cursor-pointer">
              {title}
            </h3>
            <div className="flex items-center text-sm text-gray-500 space-x-4">
              {[
                [FaHeart, likes, "text-red-500"],
                [FaComment, comments, "text-blue-500"],
                [FaShare, shares, "text-green-500"],
              ].map(([Icon, value, color], index) => (
                <span key={index} className="flex items-center">
                  <Icon className={`${color} mr-1`} />
                  {value.toLocaleString()}
                </span>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default TopStories;
