import React from "react";
import Footer from "./components/Footer";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function Layout({ children }) {
  const location = useLocation();

  const noFooterPaths = ["/login", "/signup"];

  const shouldShowFooter = !noFooterPaths.includes(location.pathname);

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow">{children}</main>
      {shouldShowFooter && <Footer />}
    </div>
  );
}
