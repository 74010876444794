import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { AiFillApple } from "react-icons/ai";
import backgroundImage from "../images/Login-SignUp-Bg.jpg";
import Logo from "../icons/black.png";

const SignUp = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/publisher");
  };

  return (
    <div className="flex min-h-screen relative">
      {/* Background image container */}
      <div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat hidden md:block"
        style={{
          backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.6), transparent), url(${backgroundImage})`,
        }}
      ></div>

      {/* SignUp form container */}
      <div className="w-full md:w-[30rem] lg:w-[30rem] xl:w-[30rem] md:ml-auto md:mr-12 lg:mr-24 xl:mr-36 my-auto p-8 md:p-12 bg-white md:bg-opacity-95 md:shadow-2xl md:backdrop-blur-sm md:rounded-lg">
        <div className="mb-8">
          <div className="flex justify-center mb-4">
            <img src={Logo} alt="Syndex AI Logo" className="h-16 w-auto" />
          </div>
          <h1 className="text-3xl text-center font-extrabold text-primary-600 uppercase tracking-wide relative pb-2 after:content-[''] after:absolute after:bottom-0 after:left-1/2 after:transform after:-translate-x-1/2 after:w-16 after:h-1 after:bg-primary-600 after:rounded">
            Create an Account
          </h1>
          <p className="mt-4 text-center text-sm text-neutral-600">
            Already have an account?{" "}
            <Link
              to="/login"
              className="font-medium text-primary hover:text-primary-400"
            >
              Login
            </Link>
          </p>
        </div>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-neutral-700"
            >
              First Name
            </label>
            <input
              id="first-name"
              name="first-name"
              type="text"
              required
              className="mt-1 block w-full px-3 py-2 bg-neutral-50 border border-neutral-300 rounded-md shadow-sm placeholder-neutral-400 focus:outline-none focus:ring-brand focus:border-brand"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="last-name"
              className="block text-sm font-medium text-neutral-700"
            >
              Last Name
            </label>
            <input
              id="last-name"
              name="last-name"
              type="text"
              required
              className="mt-1 block w-full px-3 py-2 bg-neutral-50 border border-neutral-300 rounded-md shadow-sm placeholder-neutral-400 focus:outline-none focus:ring-brand focus:border-brand"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-neutral-700"
            >
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="mt-1 block w-full px-3 py-2 bg-neutral-50 border border-neutral-300 rounded-md shadow-sm placeholder-neutral-400 focus:outline-none focus:ring-brand focus:border-brand"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-neutral-700"
            >
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="new-password"
              required
              className="mt-1 block w-full px-3 py-2 bg-neutral-50 border border-neutral-300 rounded-md shadow-sm placeholder-neutral-400 focus:outline-none focus:ring-brand focus:border-brand"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand hover:bg-brand-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand transition duration-150 ease-in-out"
            >
              Sign up
            </button>
          </div>
        </form>
        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-neutral-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-neutral-500">or</span>
            </div>
          </div>
          <div className="mt-6 grid grid-cols-2 gap-3">
            <button className="w-full inline-flex justify-center items-center py-2 px-4 rounded-md shadow-sm bg-neutral-50 text-sm font-medium text-neutral-700 hover:bg-neutral-100 hover:shadow-md transition-all duration-200 ease-in-out">
              <FcGoogle className="w-5 h-5 mr-2" />
              <span>Google</span>
            </button>
            <button className="w-full inline-flex justify-center items-center py-2 px-4 rounded-md shadow-sm bg-neutral-50 text-sm font-medium text-neutral-700 hover:bg-neutral-100 hover:shadow-md transition-all duration-200 ease-in-out">
              <AiFillApple className="w-5 h-5 mr-2 text-black" />
              <span>Apple</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
