// JoinCreators.jsx
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Adedayo_Fashanu from "../images/Creators/Adedayo_Fashanu-Editor-in-Chief,Content_Creator,Producer,Podcaster.png";
import Geneva_Greene from "../images/Creators/Geneva_Greene-Managing_Editor,Researcher&Academia.png";
import Jonathan_Daniels from "../images/Creators/man-6203802_1280.jpg";
import Jasmine_Davis from "../images/Creators/man-712012_1920.jpg";
import Jessica_Smithson from "../images/Creators/model-8881740_1280.jpg";
import Taylor_Morrison from "../images/Creators/portrait-8036356_1280.jpg";
import Sophia_Browning from "../images/Creators/smile-2072907_1280.jpg";
import Emma_Wilson from "../images/Creators/woman-657753_1280.jpg";
import Nina_Simon from "../images/Creators/woman-7175038_1280.jpg";
import Ava_Jackson from "../images/Creators/woman-7450034_1920.jpg";
import Liam_Browne from "../images/Creators/young-1101635_1920.jpg";

const creators = [
  {
    name: "Adedayo Fashanu",
    role: "Editor in Chief",
    responsibilities: ["Content", "Podcasting", "Production"],
    image: Adedayo_Fashanu,
  },
  {
    name: "Geneva Greene",
    role: "Managing Editor",
    responsibilities: ["Research", "Academia", "Management"],
    image: Geneva_Greene,
  },
  {
    name: "Jonathan Daniels",
    role: "Reporter",
    responsibilities: ["Reporting", "Interviewing", "Writing"],
    image: Jonathan_Daniels,
  },
  {
    name: "Jasmine Davis",
    role: "Photojournalist",
    responsibilities: ["Photography", "Editing", "Storytelling"],
    image: Jasmine_Davis,
  },
  {
    name: "Jessica Smithson",
    role: "Model",
    responsibilities: ["Modeling", "Photoshoots", "Runway"],
    image: Jessica_Smithson,
  },
  {
    name: "Taylor Morrison",
    role: "Portrait Photographer",
    responsibilities: ["Portraits", "Photoshoots", "Editing"],
    image: Taylor_Morrison,
  },
  {
    name: "Sophia Browning",
    role: "Smile Photographer",
    responsibilities: ["Portraits", "Photoshoots", "Editing"],
    image: Sophia_Browning,
  },
  {
    name: "Emma Wilson",
    role: "Fashion Model",
    responsibilities: ["Modeling", "Photoshoots", "Runway"],
    image: Emma_Wilson,
  },
  {
    name: "Nina Simon",
    role: "Marketing Specialist",
    responsibilities: ["Strategy", "Analytics", "Content"],
    image: Nina_Simon,
  },
  {
    name: "Ava Jackson",
    role: "Fashion Model",
    responsibilities: ["Modeling", "Photoshoots", "Runway"],
    image: Ava_Jackson,
  },
  {
    name: "Liam Browne",
    role: "Young Model",
    responsibilities: ["Modeling", "Photoshoots", "Runway"],
    image: Liam_Browne,
  },
];

const CreatorCard = ({ creator }) => (
  <div className="flex-none w-[250px] h-[350px] relative rounded-xl overflow-hidden shadow-lg transition-transform duration-300 ease-in-out hover:-translate-y-1">
    <div
      className="w-full h-full bg-cover bg-center"
      style={{ backgroundImage: `url(${creator.image})` }}
    >
      <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300 p-5 flex flex-col justify-end">
        <h3 className="text-2xl text-white mb-1">{creator.name}</h3>
        <p className="text-base text-white opacity-80 mb-2">{creator.role}</p>
        <div className="flex flex-wrap gap-2">
          {creator.responsibilities.map((responsibility, idx) => (
            <span
              key={idx}
              className="bg-transparent border border-white text-white text-xs px-2 py-1 rounded-full shadow-sm inline-block"
            >
              {responsibility}
            </span>
          ))}
        </div>
      </div>
    </div>
  </div>
);

CreatorCard.propTypes = {
  creator: PropTypes.shape({
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    responsibilities: PropTypes.arrayOf(PropTypes.string).isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};

const JoinCreators = () => {
  const scrollRef = useRef(null);
  const duplicatedCreators = [...creators];

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (!scrollElement) return;

    let scrollInterval;
    let isPaused = false;

    const scroll = () => {
      if (!isPaused) {
        if (scrollElement.scrollLeft >= creators.length * 250) {
          scrollElement.scrollLeft = 0;
        } else {
          scrollElement.scrollLeft += 1;
        }
      }
    };

    const startScroll = () => {
      scrollInterval = setInterval(scroll, 30);
    };

    const stopScroll = () => {
      clearInterval(scrollInterval);
    };

    const pauseScroll = () => {
      isPaused = true;
    };

    const resumeScroll = () => {
      isPaused = false;
    };

    startScroll();

    scrollElement.addEventListener("mouseenter", pauseScroll);
    scrollElement.addEventListener("mouseleave", resumeScroll);

    return () => {
      stopScroll();
      scrollElement.removeEventListener("mouseenter", pauseScroll);
      scrollElement.removeEventListener("mouseleave", resumeScroll);
    };
  }, []);

  return (
    <section className="bg-white pt-[60px] md:pt-[40px]">
      <div className="max-w-[1200px] mx-auto px-5">
        <div className="flex flex-col md:flex-row justify-between items-start mb-8">
          <h2 className="text-4xl text-black font-bold md:border-r md:border-gray-300 md:pr-5 md:mr-5 mb-4 md:mb-0 text-center md:text-left w-full md:w-auto">
            Join Other Creators
          </h2>
          <p className="text-base text-[#555] leading-[1.6] flex-1 text-center md:text-left">
            Join other global & local creators and journalists to: Share your
            stories, Reach new audiences, and Build communities all while
            Earning
          </p>
        </div>
        <div className="w-screen -ml-[50vw] left-1/2 relative overflow-hidden">
          <div
            ref={scrollRef}
            className="flex gap-5 overflow-x-auto scrollbar-hide py-5 px-5"
          >
            {duplicatedCreators.map((creator, index) => (
              <CreatorCard key={index} creator={creator} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinCreators;
