import React from "react";
import {
  FaInstagram,
  FaYoutube,
  FaFacebook,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import { SiAppstore, SiGoogleplay } from "react-icons/si";
import Logo from "../icons/Teal.png";

const Footer = () => {
  const companyLinks = ["Who We Are", "For Creators", "For Brands", "Jobs"];
  const helpLinks = [
    "Terms & Conditions",
    "Privacy & Consent",
    "Cookie Policy",
  ];
  const socialIcons = [
    FaInstagram,
    FaYoutube,
    FaFacebook,
    FaTwitter,
    FaLinkedin,
  ];

  return (
    <footer className="bg-tertiary text-gray-300 py-6 px-4 w-full text-sm">
      <div className="w-full max-w-screen-xl mx-auto">
        {/* Mobile Layout */}
        <div className="md:hidden">
          <div className="flex justify-between items-center mb-6">
            <div className="flex items-center">
              <img src={Logo} alt="Syndex AI Logo" className="h-12 w-auto" />
              <h1 className="text-xl font-bold text-brand-400">SYNDEX AI</h1>
            </div>
            <div className="flex space-x-3">
              {socialIcons.map((Icon, index) => (
                <a
                  key={index}
                  href="/"
                  className="text-xl hover:text-red-500 transition-colors"
                >
                  <Icon />
                </a>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div>
              <h3 className="font-semibold mb-2 uppercase">Company</h3>
              <ul className="space-y-1">
                {companyLinks.map((item) => (
                  <li key={item}>
                    <a
                      href={`#${item.toLowerCase().replace(/\s+/g, "-")}`}
                      className="hover:text-brand-400 transition-colors"
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="font-semibold mb-2 uppercase">Help</h3>
              <ul className="space-y-1">
                {helpLinks.map((item) => (
                  <li key={item}>
                    <a
                      href={`#${item.toLowerCase().replace(/\s+/g, "-")}`}
                      className="hover:text-brand-400 transition-colors"
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="flex justify-between mb-8">
            <a
              href="#app-store"
              className="flex items-center bg-tertiary-700 text-white px-3 py-2 rounded-md hover:bg-tertiary-800 transition-colors w-[48%]"
            >
              <SiAppstore className="mr-2 text-xl" />
              <div>
                <p className="text-xs">Download on the</p>
                <p className="text-xs font-semibold">App Store</p>
              </div>
            </a>
            <a
              href="#google-play"
              className="flex items-center bg-tertiary-700 text-white px-3 py-2 rounded-md hover:bg-tertiary-800 transition-colors w-[48%]"
            >
              <SiGoogleplay className="mr-2 text-xl" />
              <div>
                <p className="text-xs">Get it on</p>
                <p className="text-xs font-semibold">Google Play</p>
              </div>
            </a>
          </div>
        </div>

        {/* Desktop Layout */}
        <div className="hidden md:flex md:flex-wrap md:justify-between">
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <div className="flex items-center mb-4">
              <img
                src={Logo}
                alt="Syndex AI Logo"
                className="h-16 w-auto mr-2"
              />
              <h1 className="text-xl font-bold text-brand-400">SYNDEX AI</h1>
            </div>
            <p className="text-sm">Empowering creators and brands worldwide.</p>
          </div>
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 className="text-lg font-semibold mb-4 uppercase">Company</h3>
            <ul className="space-y-2">
              {companyLinks.map((item) => (
                <li key={item}>
                  <a
                    href={`#${item.toLowerCase().replace(/\s+/g, "-")}`}
                    className="hover:text-brand-400 transition-colors"
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 className="text-lg font-semibold mb-4 uppercase">Help</h3>
            <ul className="space-y-2">
              {helpLinks.map((item) => (
                <li key={item}>
                  <a
                    href={`#${item.toLowerCase().replace(/\s+/g, "-")}`}
                    className="hover:text-brand-400 transition-colors"
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full md:w-1/4">
            <h3 className="text-lg font-semibold mb-4 uppercase">
              Our World Together
            </h3>
            <div className="flex space-x-4 mb-6">
              {socialIcons.map((Icon, index) => (
                <a
                  key={index}
                  href="/"
                  className="text-2xl hover:text-red-500 transition-colors"
                >
                  <Icon />
                </a>
              ))}
            </div>
            <h3 className="text-lg font-semibold mb-4 uppercase">
              Get the App
            </h3>
            <div className="space-y-2 mb-8">
              <a
                href="#app-store"
                className="flex items-center bg-tertiary-700 text-white px-4 py-2 rounded-md hover:bg-tertiary-800 transition-colors w-48"
              >
                <SiAppstore className="mr-3 text-2xl" />
                <div>
                  <p className="text-xs">Download on the</p>
                  <p className="text-sm font-semibold">App Store</p>
                </div>
              </a>
              <a
                href="#google-play"
                className="flex items-center bg-tertiary-700 text-white px-4 py-2 rounded-md hover:bg-tertiary-800 transition-colors w-48"
              >
                <SiGoogleplay className="mr-3 text-2xl" />
                <div>
                  <p className="text-xs">Get it on</p>
                  <p className="text-sm font-semibold">Google Play</p>
                </div>
              </a>
            </div>
          </div>
        </div>

        {/* Copyright - Same for both layouts */}
        <div className="pt-4 border-t border-gray-700 text-center md:text-left">
          <p className="text-xs">&copy; 2024 Syndex AI. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
